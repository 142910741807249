<template>
  <div>
    <div v-if="$vuetify.breakpoint.xsOnly" oncontextmenu="return false">
      <v-card
          :loading="loading"
          style="background-color: purple"
          elevation="9"
          dark
          :height="windowHeight + 'px'"
          :class="{ 'mx-auto blur-content': dialog || fullNameDialog }"
      >
        <div
            v-if="loading"
            style="
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          "
        >
          <v-progress-circular
              style="text-align: center"
              :size="70"
              :width="7"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </div>
        <v-card-title>
          <v-layout column align-center justify-center class="mb-2">
            <h1
                class="headline font-weight-bold center"
                style="line-height: 46px !important; padding-top: 35px"
            >
              {{ $t("loginSignup") }}
            </h1>
          </v-layout>
        </v-card-title>
        <v-card-actions>
          <v-col>
            <v-form :model="valid" ref="phoneInputFormData" lazy-validation>
              <v-card-text style="padding: 0 5px 5px 5px">{{
                  $t("enterYourEmail")
                }}
              </v-card-text>
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    style="padding-bottom: 0; padding-left: 0"
                >
                  <v-text-field
                      id="email"
                      v-model="user.email"
                      :rules="emailRules"
                      :label="$t('email')"
                      persistent-hint
                      required
                      :disabled="!!user.verificationCode"
                      placeholder="Your Email"
                      :dense="dense"
                      autocomplete="off"
                      outlined
                      ref="phoneInput"
                      autofocus
                  >
                  </v-text-field>
                </v-col>

                <v-card-text style="padding-bottom: 0px">{{
                    $t("weWillSendCode")
                  }}
                </v-card-text>

                <v-card-text style="padding-bottom: 0px">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn
                        color="primary"
                        dark
                        rounded
                        :elevation="hover ? 16 : 2"
                        :loading="loading"
                        width="100%"
                        @click="sendEmail"
                        v-shortkey="['enter']"
                        @shortkey.native="sendEmail"
                    >
                      {{ $t("sendCode") }}
                    </v-btn>
                  </v-hover>
                </v-card-text>
                <v-card-text style="padding-bottom: 0px">
                  This site is protected by reCAPTCHA and the Google
                  <a href="https://policies.google.com/privacy"
                  >Privacy Policy</a
                  >
                  and
                  <a href="https://policies.google.com/terms"
                  >Terms of Service</a
                  >
                  apply.
                </v-card-text>
                <v-card-text style="padding-bottom: 0px; text-align: center"
                >{{ $t("cantlogin") }}&nbsp;<span
                    @click="contactUs"
                    style="color: #1976d2; cursor: pointer"
                >{{ $t("contactUs") }}</span
                ></v-card-text
                >
              </v-row>
              <!--              <v-card-text style="padding: 0 5px 5px 5px">{{
                                $t("enterYourPhoneNumber")
                              }}
                            </v-card-text>
                            <v-row>
                              <v-col cols="6" sm="6" md="6" style="padding-bottom: 0">
                                <v-autocomplete
                                    v-model="user.phoneNumberPrefix"
                                    :items="phoneNumberPrefixList"
                                    :disabled="!!user.verificationCode"
                                    :dense="dense"
                                    color="blue-grey lighten-2"
                                    :label="$t('countryCode')"
                                    item-text="number"
                                    item-value="number"
                                    required
                                    :rules="phoneNumberPrefixInput"
                                    @change="focusOnPhoneInput"
                                >
                                  <template v-slot:selection="data">
                                    <v-chip v-bind="data.attrs" :input-value="data.selected">
                                      <v-avatar left>
                                        <v-img :src="data.item.flag" :alt="data.item.number"></v-img>
                                      </v-avatar>
                                      {{ data.item.number }}
                                    </v-chip>
                                  </template>
                                  <template v-slot:item="data">
                                    <v-list-item-avatar>
                                      <img :src="data.item.flag" :alt="data.item.number"/>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                      &lt;!&ndash; <v-list-item-title v-html="data.item.name"></v-list-item-title> &ndash;&gt;
                                      <v-list-item-subtitle
                                          v-html="data.item.number"
                                      ></v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col
                                  cols="6"
                                  sm="6"
                                  md="6"
                                  style="padding-bottom: 0; padding-left: 0"
                              >
                                <v-text-field
                                    id="phoneNumber"
                                    v-model="user.phoneNumber"
                                    :rules="phoneNumberInput"
                                    :label="$t('phoneNumber')"
                                    persistent-hint
                                    required
                                    :disabled="!!user.verificationCode"
                                    placeholder="12563226"
                                    maxlength="11"
                                    :dense="dense"
                                    autocomplete="off"
                                    ref="phoneInput"
                                    autofocus
                                >
                                </v-text-field>
                              </v-col>
                              <v-card-text style="padding-bottom: 0px">{{
                                  $t("weWillSendCode")
                                }}
                              </v-card-text>
                              &lt;!&ndash; For the confirmation code input dialog &ndash;&gt;
                              <v-card-text style="padding-bottom: 0px">
                                <v-hover v-slot:default="{ hover }" close-delay="200">
                                  <v-btn
                                      id="sign-in-button"
                                      color="primary"
                                      dark
                                      rounded
                                      :elevation="hover ? 16 : 2"
                                      :loading="loading"
                                      width="100%"
                                  >
                                    {{ $t("sendCode") }}
                                  </v-btn>
                                </v-hover>
                              </v-card-text>
                              <v-card-text style="padding-bottom: 0px">
                                This site is protected by reCAPTCHA and the Google
                                <a href="https://policies.google.com/privacy"
                                >Privacy Policy</a
                                >
                                and
                                <a href="https://policies.google.com/terms"
                                >Terms of Service</a
                                >
                                apply.
                              </v-card-text>
                              <v-card-text style="padding-bottom: 0px; text-align: center"
                              >{{ $t("cantlogin") }}&nbsp;<span
                                  @click="contactUs"
                                  style="color: #1976d2; cursor: pointer"
                              >{{ $t("contactUs") }}</span
                              ></v-card-text
                              >
                            </v-row>-->
            </v-form>
          </v-col>
        </v-card-actions>

        <v-card-actions style="padding: 0px !important">
          <v-spacer></v-spacer>
          <v-btn text to="/" x-small>
            {{ $t("backToWeb") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>

      <v-snackbar v-model="isCorrect" :timeout="timeout" top color="orange">
        {{ $t(messageError) }}
        <v-btn color="white" text @click="isCorrect = false"> Close</v-btn>
      </v-snackbar>
    </div>
    <!--======================= not for mobile devices ======================================-->
    <div v-else oncontextmenu="return false">
      <div
          id="base-login"
          :class="{ 'blur-content': dialog || fullNameDialog }"
      >
        <div
            v-if="loading"
            style="
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          "
        >
          <v-progress-circular
              style="text-align: center"
              :size="70"
              :width="7"
              color="purple"
              indeterminate
          ></v-progress-circular>
        </div>
        <v-layout
            class="auth-background"
            align-center
            justify-center
            row
            fill-height
        >
          <v-overlay :value="loading">
            <v-progress-circular
                indeterminate
                size="64"
                color="white"
            ></v-progress-circular>
          </v-overlay>

          <v-container fluid grid-list-xl>
            <v-card
                :loading="loading"
                class="mx-auto my-12"
                max-width="500"
                shaped
                :ma-0="$vuetify.breakpoint.xsOnly"
                elevation="9"
                style="background-color: #913d88"
                dark
            >
              <v-card-title style="padding: 0">
                <v-layout column align-center justify-center class="mb-2">
                  <h1
                      class="headline font-weight-bold center"
                      style="line-height: 46px !important; padding-top: 35px"
                  >
                    {{ $t("loginSignup") }}
                  </h1>
                </v-layout>
              </v-card-title>

              <v-card-actions>
                <v-col>
                  <v-form
                      :model="valid"
                      ref="phoneInputFormData"
                      lazy-validation
                  >
                    <v-card-text style="padding: 0 5px 5px 5px">{{
                        $t("enterYourEmail")
                      }}
                    </v-card-text>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          style="padding-bottom: 0; padding-left: 0"
                      >
                        <v-text-field
                            id="email"
                            v-model="user.email"
                            :rules="emailRules"
                            :label="$t('email')"
                            persistent-hint
                            required
                            :disabled="!!user.verificationCode"
                            placeholder="Your Email"
                            :dense="dense"
                            autocomplete="off"
                            outlined
                            ref="phoneInput"
                            autofocus
                        >
                        </v-text-field>
                      </v-col>

                      <!--                      <v-col cols="6" sm="6" md="6" style="padding-bottom: 0">
                                              <v-autocomplete
                                                v-model="user.phoneNumberPrefix"
                                                :items="phoneNumberPrefixList"
                                                outlined
                                                :disabled="!!user.verificationCode"
                                                :dense="dense"
                                                color="blue-grey lighten-2"
                                                :label="$t('countryCode')"
                                                item-text="number"
                                                item-value="number"
                                                required
                                                :rules="phoneNumberPrefixInput"
                                                @change="focusOnPhoneInput"
                                              >
                                                <template v-slot:selection="data">
                                                  <v-chip
                                                    v-bind="data.attrs"
                                                    :input-value="data.selected"
                                                  >
                                                    <v-avatar left>
                                                      <v-img :src="data.item.flag" :alt="data.item.number"></v-img>
                                                    </v-avatar>
                                                    {{ data.item.number }}
                                                  </v-chip>
                                                </template>
                                                <template v-slot:item="data">
                                                  <v-list-item-avatar>
                                                    <img :src="data.item.flag" :alt="data.item.number"/>
                                                  </v-list-item-avatar>
                                                  <v-list-item-content>
                                                    &lt;!&ndash; <v-list-item-title v-html="data.item.name"></v-list-item-title> &ndash;&gt;
                                                    <v-list-item-subtitle
                                                      v-html="data.item.number"
                                                    ></v-list-item-subtitle>
                                                  </v-list-item-content>
                                                </template>
                                              </v-autocomplete>
                                            </v-col>
                                            <v-col
                                              cols="6"
                                              sm="6"
                                              md="6"
                                              style="padding-bottom: 0; padding-left: 0"
                                            >
                                              <v-text-field
                                                id="phoneNumber"
                                                v-model="user.phoneNumber"
                                                :rules="phoneNumberInput"
                                                :label="$t('phoneNumber')"
                                                persistent-hint
                                                required
                                                :disabled="!!user.verificationCode"
                                                placeholder="12563226"
                                                maxlength="11"
                                                :dense="dense"
                                                autocomplete="off"
                                                outlined
                                                ref="phoneInput"
                                                autofocus
                                              >
                                              </v-text-field>
                                            </v-col>-->
                      <v-card-text style="padding-bottom: 0px">{{
                          $t("weWillSendCode")
                        }}
                      </v-card-text>
                      <v-card-text style="padding-bottom: 0px">
                        <v-hover v-slot:default="{ hover }" close-delay="200">
                          <v-btn
                              color="primary"
                              dark
                              rounded
                              :elevation="hover ? 16 : 2"
                              :loading="loading"
                              @click="sendEmail"
                              v-shortkey="['enter']"
                              @shortkey.native="sendEmail"
                              width="100%"
                          >
                            {{ $t("sendCode") }}
                          </v-btn>
                          <!--                          <v-btn
                                                        id="sign-in-button"
                                                        color="primary"
                                                        dark
                                                        rounded
                                                        :elevation="hover ? 16 : 2"
                                                        :loading="loading"
                                                        width="100%"
                                                    >
                                                      {{ $t("sendCode") }}
                                                    </v-btn>-->
                        </v-hover>
                      </v-card-text>

                      <v-card-text style="padding-bottom: 0px">
                        This site is protected by reCAPTCHA and the Google
                        <a href="https://policies.google.com/privacy"
                        >Privacy Policy</a
                        >
                        and
                        <a href="https://policies.google.com/terms"
                        >Terms of Service</a
                        >
                        apply.
                      </v-card-text>
                      <v-card-text
                          style="padding-bottom: 0px; text-align: center"
                      >{{ $t("cantlogin") }}&nbsp;<span
                          @click="contactUs"
                          style="color: #1976d2; cursor: pointer"
                      >{{ $t("contactUs") }}</span
                      ></v-card-text
                      >
                    </v-row>
                  </v-form>
                </v-col>
              </v-card-actions>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text to="/" x-small>
                  {{ $t("backToWeb") }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>

            <v-snackbar
                v-model="isCorrect"
                :timeout="timeout"
                top
                color="orange"
            >
              {{ $t(messageError) }}
              <v-btn color="white" text @click="isCorrect = false">
                Close
              </v-btn>
            </v-snackbar>
          </v-container>
        </v-layout>
      </div>
    </div>
    <!-- =========== Dialogs =============-->
    <v-row justify="space-around">
      <v-dialog
          v-model="contactUsDialog"
          transition="dialog-bottom-transition"
          max-width="300"
      >
        <v-card>
          <v-toolbar color="purple" class="text-h6" dark
          >Let us help you!
          </v-toolbar
          >
          <v-card-text>
            <div class="text-h6 pa-3">
              <v-icon>mdi-phone-dial</v-icon>&nbsp;<a href="tel:+85599262715"
            >Phone Call</a
            >
            </div>
            <div class="text-h6 pa-3">
              <v-icon>mdi-message</v-icon>&nbsp;<a
                href="https://t.me/Bayonsupport"
                target="_blank"
                rel="noopener noreferrer"
            >Telegram</a
            >
            </div>
          </v-card-text>
          <hr/>
          <v-card-actions
              class="justify-end"
              style="padding-bottom: 5px !important"
          >
            <v-btn text @click="contactUsDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogConfirmCode" persistent max-width="400px">
        <v-card>
          <v-card-title style="text-align: justify !important">
            <span class="text-h6"
            >{{ $t("enterConfirmationCode") }}
              {{ user.email }}</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
                  :model="codeValid"
                  ref="codeInputFormData"
                  lazy-validation
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        id="code"
                        v-model="user.smsCode"
                        :label="$t('code')"
                        :rules="verificationCodeRule"
                        persistent-hint
                        required
                        :disabled="!!user.verificationCode"
                        placeholder="123456"
                        maxlength="6"
                        :dense="dense"
                        autocomplete="off"
                        outlined
                        autofocus
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelConfirmation">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
                text
                color="blue darken-1"
                :loading="loading"
                @click="verifyCodeMojo"
                v-shortkey="['enter']"
                @shortkey.native="verifyCodeMojo"
            >
              {{ $t("confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="chooseAccountDialog" persistent max-width="300px">
        <v-card>
          <v-card-title style="text-align: justify !important">
            <span class="text-h6">Please choose an account</span>
          </v-card-title>
          <v-card-text>
            <ul style="list-style-type: none; padding-left: 0">
              <li
                  v-for="(account, index) in userAccounts"
                  :key="index"
                  @click="loginUserWithCurrentProfile(account)"
                  style="padding-bottom: 10px; cursor: pointer"
              >
                <v-avatar size="56" style="margin-right: 10px"
                ><img
                    alt="user"
                    v-if="
                      (account && account.url === '') ||
                      account.url === undefined
                    "
                    src="../../assets/user.png"
                    class="custom-border-style"/>
                  <img
                      :alt="account.profile.fullName"
                      v-else
                      :src="(account && account.url) || ''"
                      class="custom-border-style"
                  /></v-avatar>
                &nbsp;&nbsp;<span style="font-weight: bold">{{
                  account.profile.fullName
                }}</span>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="fullNameDialog" persistent max-width="400px">
        <v-card>
          <v-card-title style="text-align: justify !important">
            <span class="text-h6">{{ $t("fullNameSignUp") }}</span>
          </v-card-title>
          <v-card-text>
            <v-form
                :model="fullNameRequest"
                ref="fullNameRequest"
                lazy-validation
            >
              <v-text-field
                  id="fullName"
                  v-model="user.profile.fullName"
                  :label="$t('name')"
                  :rules="fullNameRule"
                  persistent-hint
                  required
                  placeholder="John Smith"
                  :disabled="!!user.verificationCode"
                  maxlength="25"
                  :dense="dense"
                  autocomplete="off"
                  outlined
                  autofocus
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="submitFullName"
                color="blue darken-1"
                :loading="loading"
                v-shortkey="['enter']"
                @shortkey.native="submitFullName"
            >
              {{ $t("continue") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import {Constants} from "../../libs/constant";
import {provider} from "@/service/provider";
import axios from "axios";
import {countyMobileList} from "../../libs/countryMobileWithFlag";
import "../../firebase/config";
import firebase from "firebase/app";
import "firebase/auth";
import MainMixin from "@/mixins/mainMixin";

export default {
  mounted() {
    this.$jQuery("body").off();
    /*window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            this.submitForm();
            console.log("verify " + response);
          },
        }
    );
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });*/
  },
  name: "Login",
  mixins: [MainMixin],
  data() {
    return {
      dense: this.$store.state.isDense,
      windowHeight: window.innerHeight,
      loading: false,
      valid: null,
      codeValid: null,
      show: false,
      isCorrect: false,
      search: null,
      timeout: 2000,
      fullPage: true,
      user: {
        _id: "",
        username: "",
        phoneNumberPrefix: "",
        phoneNumber: "",
        smsCode: "",
        verificationCode: "",
        sid: "",
        email: "",
        profile: {
          fullName: "",
          url: "",
          approved: true,
          workDayMorning: [0, 1, 2, 3, 4, 5, 6],
          workDayAfternoon: [0, 1, 2, 3, 4, 5, 6],
          workTimeMorningStart: "00:00",
          workTimeMorningEnd: "12:00",
          workTimeAfternoonStart: "12:00",
          workTimeAfternoonEnd: "23:59",
          maxUserLogin: 1,
        },
      },
      verificationCodeRule: [
        (v) => !!v || "Please type the confirmation code",
        (v) =>
            /^[0-9]{6}$/im.test(v) || "Please type a valid confirmation code",
      ],
      emailRules: [v => /.+@.+/.test(v) || 'Invalid Email address'],
      phoneNumberPrefixInput: [(V) => !!V || "Please choose a country"],
      phoneNumberInput: [
        (v) => !!v || "Please type phone number",
        (v) => /^[0-9]{6,11}$/im.test(v) || "Please type a valid phone number",
      ],
      fullNameRule: [(v) => !!v || "Please type your full name"],
      dialog: false,
      dialogConfirmCode: false,
      contactUsDialog: false,
      companyDoc: {},
      messageError: "",
      Constants: Constants,
      params: {
        client_id:
            "1081651083189-seeofeio5kff9v116m182trqf7dsvdnv.apps.googleusercontent.com",
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      phoneNumberPrefixList: countyMobileList,
      formattedPhoneNumber: "",
      chooseAccountDialog: false,
      userAccounts: [],
      fullNameDialog: false,
      fullNameRequest: null,
      authUser: {},
    };
  },

  methods: {
    submitForm() {
      let vm = this;
      if (vm.$refs.phoneInputFormData.validate()) {
        vm.loading = true;
        const phoneNumber = vm.getPhoneNumberFromUserInput();
        // console.log(phoneNumber + " submitted");
        const appVerifier = window.recaptchaVerifier;
        firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              window.confirmationResult = confirmationResult;
              // console.log("send verification code");
              // alert(
              //   "6 digit verification has been sent to your number" + phoneNumber
              // );
              vm.loading = false;
              this.dialog = true;
              // ...
            })
            .catch((error) => {
              // Error; SMS not sent
              // ...
              // alert(error.message);
              vm.$message({
                message: error.message,
                showClose: true,
                type: "error",
              });
              vm.loading = false;
              // console.log(error);
            });
      } else {
        window.grecaptcha.reset(window.recaptchaWidgetId);
      }
    },
    getPhoneNumberFromUserInput() {
      let vm = this;
      if (vm.user.phoneNumber.startsWith("0")) {
        let removedZero = vm.user.phoneNumber.slice(1);
        vm.formattedPhoneNumber = vm.user.phoneNumberPrefix + removedZero;
      } else {
        vm.formattedPhoneNumber =
            vm.user.phoneNumberPrefix + vm.user.phoneNumber;
      }
      // console.log(vm.formattedPhoneNumber + " formatted no");
      return vm.formattedPhoneNumber;
    },
    getCodeFromUserInput() {
      let vm = this;
      const code = vm.user.smsCode;
      // console.log(code + " user input code");
      return code;
    },
    sendEmail() {
      let vm = this;
      if (vm.$refs.phoneInputFormData.validate()) {
        vm.loading = true;

        let mojoDoc = {};
        mojoDoc.email = vm.user.email;
        return new Promise((resolve, reject) => {
          axios
              .post(provider.mojoauthSendEmail, mojoDoc, {
                headers: {
                  "X-API-Key": provider.mojoKey,
                  "Content-Type": "application/json"
                }
              })
              .then(
                  res => {
                    if (res.status == 200) {
                      resolve(res.data.state_id);
                      vm.loading = false;
                      vm.user.sid = res.data.state_id;
                      vm.dialogConfirmCode = true;
                      //vm.getUerInfo(vm.formattedPhoneNumber);
                    }
                  },
                  error => {
                    reject(error);
                    vm.loading = false;
                    vm.$message({
                      message: error.message,
                      showClose: true,
                      type: "error",
                    });
                  }
              );
        });
      }
    },
    verifyCodeMojo() {
      let vm = this;
      if (vm.$refs.codeInputFormData.validate()) {
        vm.loading = true;

        const mojoDoc = new URLSearchParams();
        mojoDoc.append('OTP', vm.user.smsCode);
        mojoDoc.append('state_id', vm.user.sid);
        return new Promise((resolve, reject) => {
          axios
              .post(provider.mojoauthEmailVerify, mojoDoc, {
                headers: {
                  "X-API-Key": provider.mojoKey,
                  "Content-Type": "application/x-www-form-urlencoded"
                }
              })
              .then(
                  res => {
                    if (res.status == 200) {
                      if (res.data.authenticated === true) {
                        resolve(res.data.authenticated);
                        vm.getUerInfo(vm.user.email);
                      }
                      vm.loading = false;
                      vm.dialogConfirmCode = false;
                    }
                  },
                  error => {
                    reject(error);
                    vm.loading = false;
                    vm.$message({
                      message: error.message,
                      showClose: true,
                      type: "error",
                    });
                  }
              );
        });
      }
    },
    verifyCode() {
      // window.confirmationResult = undefined;
      let vm = this;
      if (vm.$refs.codeInputFormData.validate()) {
        vm.loading = true;
        // [START auth_phone_verify_code]
        const code = this.getCodeFromUserInput();
        // console.log(code + "verify");
        window.confirmationResult
            .confirm(code)
            .then((result) => {
              // User signed in successfully.
              vm.authUser = result.user;
              // console.log(user.uid);
              vm.dialog = false;
              // alert("successfully signed in user account");
              // ...
              vm.user.username = vm.formattedPhoneNumber;
              // vm.user.profile.fullName = vm.formattedPhoneNumber;
              vm.user.profile.phoneNumber = vm.formattedPhoneNumber;
              //check the phone number is existing or not
              let urlForPhoneCheck =
                  provider.baseURL +
                  `/users/byUserOrPhoneNumber?search=${vm.formattedPhoneNumber}`;
              return new Promise((resolve, reject) => {
                axios
                    .get(urlForPhoneCheck, {headers: {token: provider.token}})
                    .then(
                        (res) => {
                          if (res.data.code === 201) {
                            resolve(res.data.data);
                            console.log("check phone");
                            if (res.data.data[0]) {
                              // existing user login
                              console.log("phone exit");
                              // vm.getUerInfo(vm.formattedPhoneNumber);
                              vm.loginUserWithCurrentProfile(
                                  res.data.data[0] && res.data.data[0]
                              );
                            } else {
                              console.log("phone not exist");
                              // new user register
                              vm.user.roles = ["Member"];
                              vm.fullNameDialog = true;
                              vm.loading = false;
                            }
                          }
                        },
                        (error) => {
                          reject(error);
                        }
                    );
              });
            })
            .catch((error) => {
              // User couldn't sign in (bad verification code?)
              // ...
              // console.log(error);
              vm.user.smsCode = "";
              // alert(error.message);
              vm.$message({
                message: error.message,
                showClose: true,
                type: "error",
              });
              vm.loading = false;
              window.grecaptcha.reset(window.recaptchaWidgetId);
            });
        // [END auth_phone_verify_code]
      }
    },
    cancelConfirmation() {
      let vm = this;
      this.dialogConfirmCode = false;
      vm.user.smsCode = "";
    },
    updateFullName(registeredUserId) {
      let vm = this;
      let newDoc = {};
      newDoc.user_id = registeredUserId;
      newDoc.phoneNumberPrefix = vm.user.phoneNumberPrefix;
      // console.log("before update phone " + newDoc.phoneNumberPrefix);
      newDoc.phoneNumber = vm.formattedPhoneNumber;
      newDoc.fullName = vm.user.profile.fullName;
      newDoc.email = '';
      newDoc.telegram = '';
      newDoc.facebook = '';
      newDoc.dob = '';
      newDoc.type = '';
      newDoc.education = '';
      newDoc.occupation = '';
      newDoc.yearly_income = '';
      newDoc.job_level = '';
      let url = provider.baseURL + `/users/update_profile`;
      return new Promise((resolve, reject) => {
        axios
            .post(url, newDoc, {headers: {token: provider.token}})
            .then(
                res => {
                  if (res.data.code === 201) {
                    resolve(res.data);
                    vm.loading = false;
                    vm.getUerInfo(vm.formattedPhoneNumber);
                  }
                },
                error => {
                  reject(error);
                  vm.loading = false;
                  vm.$message({
                    message: error.message,
                    showClose: true,
                    type: "error",
                  });
                }
            );
      });

    },
    submitFullName() {
      let vm = this;
      if (vm.$refs.fullNameRequest.validate()) {
        vm.loading = true;
        let hashed = vm.getHashForPassword(vm.formattedPhoneNumber);
        console.log(hashed);
        let url = provider.baseURL + `/users/register`;
        return new Promise((resolve, reject) => {
          axios
              .post(
                  url,
                  {
                    username: vm.user.username,
                    password: hashed,
                    phoneNumberPrefix: vm.user.phoneNumberPrefix,
                    phoneNumber: vm.formattedPhoneNumber,
                    email: vm.user.email,
                    smsCode: vm.user.smsCode || "",
                  },
                  {headers: {token: provider.token}}
              )
              .then(
                  (res) => {
                    if (res.data.code === 201) {
                      resolve(res.data.data);
                      // vm.$message({
                      //   message: this.$t("successNotification"),
                      //   showClose: true,
                      //   type: "success",
                      // });
                      vm.updateFullName(res.data.data);
                    }
                  },
                  (error) => {
                    reject(error);
                    vm.$message({
                      message: error.message,
                      showClose: true,
                      type: "error",
                    });
                  }
              );
        });
      } else {
        vm.$message({
          message: "Please enter your full name!",
          showClose: true,
          type: "warning",
        });
      }
    },
    getUerInfo(phoneNumber) {
      let vm = this;
      let url =
          provider.baseURL + `/users/byUserOrPhoneNumber?search=${phoneNumber}`;
      return new Promise((resolve, reject) => {
        axios.get(url, {headers: {token: provider.token}}).then(
            (res) => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                // console.log("number of account " + res.data.data.length);
                if (res.data.data.length > 1) {
                  vm.userAccounts = res.data.data;
                  vm.chooseAccountDialog = true;
                } else {
                  vm.loginUserWithCurrentProfile(
                      res.data.data[0] && res.data.data[0]
                  );
                }
              } else {
                vm.isCorrect = true;
              }
            },
            (error) => {
              reject(error);
              vm.$message({
                message: error.message,
                showClose: true,
                type: "error",
              });
            }
        );
      });
    },

    loginUserWithCurrentProfile(doc) {
      // console.log("account login" + doc.username);
      let vm = this;
      vm.$store.state.userDoc = doc;
      localStorage.id = doc._id;
      localStorage.username = doc.username;
      localStorage.fullName = doc.phoneNumber || "";
      localStorage.phoneNumber = doc.phoneNumber || "";
      localStorage.userType = doc.userType || "";
      localStorage.url = doc.url || localStorage.url || "";
      if (doc.country || localStorage.country) {
        localStorage.country = doc.country || localStorage.country;
      }
      if (vm.$router.history.current.path !== "/") {
        vm.$router.replace("/");
        vm.loading = false;
        vm.$message({
          message: this.$t("successNotification"),
          showClose: true,
          type: "success",
        });
      }
    },
    focusOnPhoneInput() {
      this.$nextTick(() => this.$refs.phoneInput.focus());
    },
    contactUs() {
      this.contactUsDialog = true;
    },
  },
  created() {
    let vm = this;
    let filteredCountry = countyMobileList.filter(function (element) {
      if (element.value === localStorage.country) {
        return element.number;
      }
    });
    vm.user.phoneNumberPrefix = filteredCountry[0].number;
    // console.log(vm.user.phoneNumberPrefix);
  },
};

document.onkeydown = function (e) {
  if (event.keyCode == 123) {
    return false;
  }
  if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
    return false;
  }
  if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
    return false;
  }
  if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
    return false;
  }
  if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
    return false;
  }
};
</script>
<style scoped>
#base-login {
  background-image: linear-gradient(
      to top,
      rgb(145, 61, 136),
      rgba(145, 61, 136, 1)
  );
  /*background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../assets/images/login.jpg');*/
  background-position: center center;
  background-size: cover;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  /*z-index: 1;*/
  /*width: 0;*/
  /*height: 0;*/
  background-size: cover;
}

.blur-content {
  filter: blur(2px);
}
</style>

